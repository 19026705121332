import { Component } from '@angular/core';
import Modal from 'bootstrap/js/dist/modal';

import { ConfirmModalService } from './confirm-modal.service';

declare const $: any;

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  constructor(public confirmModalService: ConfirmModalService) {}

  close() {
    const bootstrapModal = Modal.getOrCreateInstance(document.getElementById(this.confirmModalService.option.modalId)!);
    bootstrapModal.hide();
  }

  confirm() {
    this.close();
    this.confirmModalService.confirmSubject.next('confirmed');
  }

  getIconLink(messageType: string) {
    return `assets/svg/symbol-defs.svg#sie-${messageType[0].toUpperCase()}${messageType.slice(1)}`;
  }
}
