import { Component } from '@angular/core';
import { Language } from '../shared/enums/language.enum';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss']
})
export class FooterNewComponent {
  Language = Language;
}
