<div #toastElement class="toast fade toast-width mt-2" role="alert" aria-live="assertive" aria-atomic="true"
  (click)="onClickToastr()" [ngClass]="{'clickable': clickable}">
  <div class="toast-header">
    <!-- <strong class="me-auto">{{ title }}</strong> -->
    <button type="button" aria-label="Close" (click)="hide($event)">
      <svg>
        <use xlink:href="assets/svg/symbol-defs.svg#sie-Close-small"></use>
      </svg>
    </button>
  </div>
  <div class="toast-body"
    [ngClass]="{'success': type === EventTypes.Success, 'error':type === EventTypes.Error, 'warning':type === EventTypes.Warning , 'info': type === EventTypes.Info}">
    <span class="icon">
      <svg *ngIf="type === EventTypes.Success" class="success">
        <use xlink:href="assets/svg/symbol-defs.svg#sie-Success"></use>
      </svg>

      <svg *ngIf="type === EventTypes.Error" class="error">
        <use xlink:href="assets/svg/symbol-defs.svg#sie-Error"></use>
      </svg>

      <svg *ngIf="type === EventTypes.Warning">
        <use xlink:href="assets/svg/symbol-defs.svg#sie-Warning"></use>
      </svg>

      <svg *ngIf="type === EventTypes.Info">
        <use xlink:href="assets/svg/symbol-defs.svg#sie-Information"></use>
      </svg>
    </span>

    <span class="message" [innerHtml]="message"></span>
  </div>
</div>