<div class="root">
  <div class="main">
    <a href="https://www.siemens.com" target="_blank" class="logo-box">
      <img class="logo" src="assets/SiemensLogo.svg" alt="Logo" />
    </a>
    <div class="app-name">
      <a href="https://www.siemens.com/global/en/products/buildings/partners/partner-network.html">
        Partner Portal
      </a>
    </div>

    <div class="language-box">
      <div class="language-item" *ngIf="!profileFound">
        <svg role="img" class="icon">
          <use xlink:href="assets/svg/symbol-defs.svg#sie-Login"></use>
        </svg>
        <a href="#" class="me-3" (click)="signIn()">{{ 'sign.in' | translate }}</a>
      </div>

      <div class="d-flex language-item">
        <svg role="img" class="languge-icon align-self-center">
          <use xlink:href="assets/svg/symbol-defs.svg#sie-Language"></use>
        </svg>
        <select title="{{'language' | translate}}" class="form-select sie-floating-select mb-2"
          (ngModelChange)="changeLanguage($event)" [ngModel]="userLang">
          <option [value]="'en'">{{'English' | translate}}</option>
          <option [value]="'nl'">{{'Dutch' | translate}}</option>
          <option [value]="'zh'">{{'Chinese' | translate}}</option>
        </select>
      </div>
    </div>
  </div>

  <nav class="navigation" app-navigation></nav>
</div>