import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Registration } from '../shared/interfaces/registration';
import { Response } from '../shared/interfaces/response';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private serverUrl: string;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.serverUrl = `${environment.serverUrl}/registration`;
  }

  register(registration: Registration): Observable<Response> {
    return this.http.post<Response>(this.serverUrl, registration, { withCredentials: true });
  }

}
