import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventTypes } from '../interfaces/event-types';
import { ToastEvent } from '../interfaces/toast-event';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastEvents: Observable<ToastEvent>;
  private _toastEvents = new Subject<ToastEvent>();
  private _clickSubject = new Subject<any>();

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
  }

  public get clickSubject() {
    return this._clickSubject;
  }
  public set clickSubject(value) {
    this._clickSubject = value;
  }

  showToast(title: string, message: string, type: EventTypes, clickable: boolean) {
    // console.log(title, message, type, clickable);
    this._toastEvents.next({ message, title, type, clickable });
  }
}
