import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
declare const $: any;
import Modal from 'bootstrap/js/dist/modal';

interface ConfirmModalOptions {
  modalId: string;
  title: string;
  text: string;
  hasCancelButton?: boolean;
  confirmButtonTitle: string;
  cancelButtonTitle?: string;
  backdrop?: 'static' | boolean;
  keyboard?: boolean;
  messageType?: 'success' | 'error' | 'warning';
  hideCloseButton?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {
  confirmSubject = new Subject<any>();

  private _option: ConfirmModalOptions = {
    modalId: 'modal',
    title: '',
    text: '',
    hasCancelButton: false,
    confirmButtonTitle: 'Yes',
    cancelButtonTitle: 'Cancel',
    backdrop: true,
    keyboard: true,
    hideCloseButton: false
  };

  open(options: ConfirmModalOptions) {
    this._option = { ...this._option, ...options };

    const bootstrapTimeout = setTimeout(() => {
      const bootstrapModal = Modal.getOrCreateInstance(document.getElementById(this._option.modalId)!, {
        backdrop: this._option.backdrop,
        keyboard: this._option.keyboard
      });

      bootstrapModal.show();
      clearTimeout(bootstrapTimeout);
    }, 300);

    return new Promise<any>((resolve, reject) => {
      const confirmSubscription = this.confirmSubject.subscribe(() => {
        confirmSubscription.unsubscribe();
        resolve(true);
      });
    });
  }

  get option() {
    return this._option;
  }
}
