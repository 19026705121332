import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  readonly availableLanguages = ['en', 'nl', 'zh'];
  readonly defaultLang = 'en';

  constructor(private translate: TranslateService) {
    translate.setDefaultLang(this.defaultLang);
    translate.onLangChange.subscribe(this.storeLanguage);

    const language = this.loadLanguage() ?? translate.getBrowserLang() ?? this.defaultLang;
    if (this.availableLanguages.some(l => l === language)) {
      this.setLanguage(language);
    } else {
      this.setLanguage(this.defaultLang);
    }
  }

  get language() {
    return this.translate.currentLang;
  }

  setLanguage(language: string) {
    this.translate.use(language);
  }

  private storeLanguage(event: LangChangeEvent) {
    localStorage.setItem('lang', event.lang);
  }

  private loadLanguage() {
    return localStorage.getItem('lang');
  }
}
