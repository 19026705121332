export const countries = [
  { id: 'AF', title: 'Afghanistan' },
  { id: 'AL', title: 'Albania' },
  { id: 'DZ', title: 'Algeria' },
  { id: 'AO', title: 'Angola' },
  { id: 'AI', title: 'Anguilla' },
  { id: 'AG', title: 'Antigua and Baruda' },
  { id: 'AR', title: 'Argentina' },
  { id: 'AM', title: 'Armenia' },
  { id: 'AW', title: 'Aruba' },
  { id: 'AU', title: 'Australia' },
  { id: 'AT', title: 'Austria' },
  { id: 'AZ', title: 'Azerbaidjan' },
  { id: 'BS', title: 'Bahamas' },
  { id: 'BH', title: 'Bahrain' },
  { id: 'BD', title: 'Bangladesh' },
  { id: 'BB', title: 'Barbados' },
  { id: 'BY', title: 'Belarus' },
  { id: 'BE', title: 'Belgium' },
  { id: 'BZ', title: 'Belize' },
  { id: 'BJ', title: 'Benin' },
  { id: 'BM', title: 'Bermuda' },
  { id: 'BO', title: 'Bolivia' },
  { id: 'BA', title: 'Bosnia and Herzegovina' },
  { id: 'BW', title: 'Botswana' },
  { id: 'BR', title: 'Brazil' },
  { id: 'BN', title: 'Brunei' },
  { id: 'BG', title: 'Bulgaria' },
  { id: 'BF', title: 'Burkina Faso' },
  { id: 'BI', title: 'Burundi' },
  { id: 'KH', title: 'Cambodia' },
  { id: 'CM', title: 'Cameroon' },
  { id: 'CA', title: 'Canada' },
  { id: 'KY', title: 'Cayman Islands' },
  { id: 'CF', title: 'Central African Republic' },
  { id: 'TD', title: 'Chad' },
  { id: 'CL', title: 'Chile' },
  { id: 'CN', title: 'China' },
  { id: 'CO', title: 'Colombia' },
  { id: 'KM', title: 'Comoros' },
  { id: 'CG', title: 'Congo' },
  { id: 'CR', title: 'Costa Rica' },
  { id: 'HR', title: 'Croatia' },
  { id: 'CU', title: 'Cuba' },
  { id: 'CY', title: 'Cyprus' },
  { id: 'CZ', title: 'Czech Republic' },
  { id: 'CD', title: 'Democratic Rep Congo' },
  { id: 'DK', title: 'Denmark' },
  { id: 'DJ', title: 'Djibouti' },
  { id: 'DM', title: 'Dominica' },
  { id: 'DO', title: 'Dominican Republic' },
  { id: 'EC', title: 'Ecuador' },
  { id: 'EG', title: 'Egypt' },
  { id: 'SV', title: 'El Salvador' },
  { id: 'GQ', title: 'Equatorial Guinea' },
  { id: 'ER', title: 'Eritrea' },
  { id: 'EE', title: 'Estonia' },
  { id: 'ET', title: 'Ethiopia' },
  { id: 'FK', title: 'Falkland Island' },
  { id: 'FI', title: 'Finland' },
  { id: 'FR', title: 'France' },
  { id: 'GA', title: 'Gabon' },
  { id: 'GM', title: 'Gambia' },
  { id: 'GE', title: 'Georgia' },
  { id: 'DE', title: 'Germany' },
  { id: 'GH', title: 'Ghana' },
  { id: 'GR', title: 'Greece' },
  { id: 'GD', title: 'Grenada' },
  { id: 'GT', title: 'Guatemala' },
  { id: 'GN', title: 'Guinea' },
  { id: 'GW', title: 'Guinea Bissau' },
  { id: 'GY', title: 'Guyana' },
  { id: 'HT', title: 'Haiti' },
  { id: 'HN', title: 'Honduras' },
  { id: 'HK', title: 'Hong Kong' },
  { id: 'HU', title: 'Hungary' },
  { id: 'IS', title: 'Iceland' },
  { id: 'IN', title: 'India' },
  { id: 'ID', title: 'Indonesia' },
  { id: 'IR', title: 'Iran' },
  { id: 'IQ', title: 'Iraq' },
  { id: 'IE', title: 'Ireland' },
  { id: 'IL', title: 'Israel' },
  { id: 'IT', title: 'Italy' },
  { id: 'CI', title: 'Ivory Coast' },
  { id: 'JM', title: 'Jamaica' },
  { id: 'JP', title: 'Japan' },
  { id: 'JO', title: 'Jordan' },
  { id: 'KZ', title: 'Kazakhstan' },
  { id: 'KE', title: 'Kenya' },
  { id: 'XK', title: 'Kosovo' },
  { id: 'KW', title: 'Kuwait' },
  { id: 'KG', title: 'Kyrgyzstan' },
  { id: 'LA', title: 'Laos' },
  { id: 'LV', title: 'Latvia' },
  { id: 'LB', title: 'Lebanon' },
  { id: 'LS', title: 'Lesotho' },
  { id: 'LR', title: 'Liberia' },
  { id: 'LY', title: 'Libya' },
  { id: 'LT', title: 'Lithuania' },
  { id: 'LU', title: 'Luxembourg' },
  { id: 'MO', title: 'Macau' },
  { id: 'MG', title: 'Madagascar' },
  { id: 'MW', title: 'Malawi' },
  { id: 'MY', title: 'Malaysia' },
  { id: 'ML', title: 'Mali' },
  { id: 'MT', title: 'Malta' },
  { id: 'MR', title: 'Mauritania' },
  { id: 'MU', title: 'Mauritius' },
  { id: 'MX', title: 'Mexico' },
  { id: 'MD', title: 'Moldavia' },
  { id: 'MN', title: 'Mongolia' },
  { id: 'MS', title: 'Monserrat' },
  { id: 'ME', title: 'Montenegro' },
  { id: 'MA', title: 'Morocco' },
  { id: 'MZ', title: 'Mozambique' },
  { id: 'MM', title: 'Myanmar' },
  { id: 'NA', title: 'Namibia' },
  { id: 'AN', title: 'Netherland Antilles' },
  { id: 'NL', title: 'Netherlands' },
  { id: 'NZ', title: 'New Zealand' },
  { id: 'NI', title: 'Nicaragua' },
  { id: 'NE', title: 'Niger' },
  { id: 'NG', title: 'Nigeria' },
  { id: 'MK', title: 'North Macedonia' },
  { id: 'NO', title: 'Norway' },
  { id: 'OM', title: 'Oman' },
  { id: 'PK', title: 'Pakistan' },
  { id: 'PS', title: 'Palestine' },
  { id: 'PA', title: 'Panama' },
  { id: 'PY', title: 'Paraguay' },
  { id: 'PE', title: 'Peru' },
  { id: 'PH', title: 'Philippines' },
  { id: 'PL', title: 'Poland' },
  { id: 'PT', title: 'Portugal' },
  { id: 'PR', title: 'Puerto Rico' },
  { id: 'QA', title: 'Qatar' },
  { id: 'RO', title: 'Romania' },
  { id: 'RU', title: 'Russia' },
  { id: 'RW', title: 'Rwanda' },
  { id: 'SH', title: 'Saint Helena' },
  { id: 'ST', title: 'SaoTome and Principe' },
  { id: 'SA', title: 'Saudi Arabia' },
  { id: 'SN', title: 'Senegal' },
  { id: 'RS', title: 'Serbia' },
  { id: 'SC', title: 'Seychelles' },
  { id: 'SL', title: 'Sierra Leone' },
  { id: 'SG', title: 'Singapore' },
  { id: 'SK', title: 'Slovakia' },
  { id: 'SI', title: 'Slovenia' },
  { id: 'SO', title: 'Somalia' },
  { id: 'ZA', title: 'South Africa' },
  { id: 'KR', title: 'South Korea' },
  { id: 'SS', title: 'South Sudan' },
  { id: 'ES', title: 'Spain' },
  { id: 'LK', title: 'Srilanka' },
  { id: 'KN', title: 'St. Kitts and Nevis' },
  { id: 'LC', title: 'St. Lucia' },
  { id: 'VC', title: 'St. Vincent and Grenadines' },
  { id: 'SD', title: 'Sudan' },
  { id: 'SR', title: 'Surititle' },
  { id: 'SZ', title: 'Swaziland' },
  { id: 'SE', title: 'Sweden' },
  { id: 'CH', title: 'Switzerland' },
  { id: 'SY', title: 'Syria' },
  { id: 'TW', title: 'Taiwan' },
  { id: 'TJ', title: 'Tajikistan' },
  { id: 'TZ', title: 'Tanzania' },
  { id: 'TH', title: 'Thailand' },
  { id: 'TG', title: 'Togo' },
  { id: 'TT', title: 'Trinidad &amp; Tobago' },
  { id: 'TN', title: 'Tunisia' },
  { id: 'TR', title: 'Turkey' },
  { id: 'TM', title: 'Turkmenistan' },
  { id: 'TC', title: 'Turks and Caicos Islands' },
  { id: 'UG', title: 'Uganda' },
  { id: 'UA', title: 'Ukraine' },
  { id: 'AE', title: 'United Arab Emirates' },
  { id: 'GB', title: 'United Kingdom' },
  { id: 'US', title: 'United States of America' },
  { id: 'UY', title: 'Uruguay' },
  { id: 'UZ', title: 'Uzbekistan' },
  { id: 'VE', title: 'Venezuela' },
  { id: 'VN', title: 'Vietnam' },
  { id: 'VG', title: 'Virgin Islands' },
  { id: 'VI', title: 'Virgin Islands (British)' },
  { id: 'EH', title: 'Western Saharah' },
  { id: 'YE', title: 'Yemen' },
  { id: 'ZM', title: 'Zambia' },
  { id: 'ZW', title: 'Zimbabwe' }
];
