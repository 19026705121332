import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EventTypes } from '../interfaces/event-types';
import { ToastService } from '../services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            this.toastService.showToast(error.error.message, error.statusText, EventTypes.Warning, false);
            break;
          case 403:
            this.toastService.showToast('Solution code is not correct', '', EventTypes.Warning, false);
            break;
          case 409:
            this.toastService.showToast(
              'Error',
              error.error?.message ? error.error.message : 'This login already exists. Please sign in with Login & Password.',
              EventTypes.Error,
              false
            );
            break;
          case 500:
            this.toastService.showToast(error.message, error.statusText, EventTypes.Error, false);
            break;
          default:
            this.toastService.showToast('', 'Internal error', EventTypes.Error, false);
            break;
        }

        return throwError(error);
      })
    );
  }
}
