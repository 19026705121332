<div class="modal fade confirm-modal" [id]="confirmModalService.option.modalId" tabindex="-1" role="dialog" aria-labelledby="ConfirmModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ConfirmModal" >{{confirmModalService.option.title}}</h5>
          <svg class="close-icon" data-dismiss="modal" aria-label="Close" (click)="close()" *ngIf="!confirmModalService.option.hideCloseButton">
            <use xlink:href="assets/svg/symbol-defs.svg#sie-Close-small"></use>
          </svg>
        </div>
        <div class="modal-body">
          <svg class="message-icons"  [ngClass]="[confirmModalService.option.messageType]" 
            data-dismiss="modal" aria-label="Close" *ngIf="confirmModalService.option.messageType">
            <use attr.xlink:href="{{getIconLink(confirmModalService.option.messageType)}}"></use>
          </svg>
          {{confirmModalService.option.text}}
        </div>
        <div class="modal-footer">
          <button type="button" class="sie-button button-sm sie-button-secondary" data-dismiss="modal" (click)="close()" *ngIf="confirmModalService.option.hasCancelButton"> {{confirmModalService.option.cancelButtonTitle}}</button>
          <button type="button" class="sie-button button-sm sie-button-primary " (click)="confirm()">{{confirmModalService.option.confirmButtonTitle}}</button>
        </div>
      </div>
    </div>
  </div>