<!-- Toaster -->
<app-toaster></app-toaster>

<div class="d-flex flex-column min-vh-100">
  <div class="app">
  <div>
    <app-header-new></app-header-new>
  </div>
  <main>
    <router-outlet></router-outlet>
  </main>
  <app-footer-new></app-footer-new>
</div>
  <!-- <app-header></app-header>

  <main class="flex-fill" >
    <router-outlet #route="outlet"></router-outlet>
  </main>

  <app-footer></app-footer> -->
  <app-confirm-modal></app-confirm-modal>
  <ngx-spinner type="ball-fall" size="medium"></ngx-spinner>
