import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Toast } from 'bootstrap';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { EventTypes } from '../../interfaces/event-types';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  EventTypes = EventTypes;
  @Output() disposeEvent = new EventEmitter();

  @ViewChild('toastElement', { static: true }) toastEl!: ElementRef;

  @Input() type!: EventTypes;

  @Input() title!: string;

  @Input() message!: string;

  toast!: Toast;

  @Input() clickable = false;

  ngOnInit() {
    this.show();
  }

  constructor(private toastrService: ToastService) {}

  show() {
    this.toast = new Toast(this.toastEl.nativeElement, {
      delay: 5000
    });

    fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
      .pipe(take(1))
      .subscribe(() => this.hide());

    this.toast.show();
  }

  hide(event?: any) {
    if (event) {
      event.stopPropagation();
    }

    this.toast.dispose();
    this.disposeEvent.emit();
  }

  onClickToastr() {
    if (this.clickable) {
      this.toastrService.clickSubject.next(null);
      this.hide();
    }
  }
}
