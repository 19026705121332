const APP_NAME = 'partner-portal';

export function setLocalItem(key: string, value: string) {
  localStorage.setItem(APP_NAME + '.' + key, value);
}

export function getLocalItem(key: string) {
  return localStorage.getItem(APP_NAME + '.' + key);
}

export function removeLocalItem(key: string) {
  localStorage.removeItem(APP_NAME + '.' + key);
}
