import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import { getLocalItem, setLocalItem } from '../shared/utils/local-storage-service';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit {
  profileFound = false;
  userLang: string;

  constructor(private translate: TranslateService, private authService: AuthService) {
    const language = getLocalItem('userLangPreference') || 'en';
    this.userLang = language;
    translate.use(language);
  }

  ngOnInit(): void {
    this.getProfile();
  }

  changeLanguage($event: string) {
    this.userLang = $event;
    this.translate.use(this.userLang).subscribe(() => {
      setLocalItem('userLangPreference', this.userLang);
    });
  }

  signIn() {
    open(`${environment.serverUrl}/auth`, '_self');
  }

  getProfile(): void {
    this.authService.getProfile().subscribe({
      next: response => {
        // console.log(`Profile response: ${JSON.stringify(response)}`);

        if (response?.id) {
          this.profileFound = true;
        }
      },
      error: err => {
        console.error(err);
      }
    });
  }
}
